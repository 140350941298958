<template>
	<div class="layout-footer">
		<img alt="Logo" src="/chillpainai/icon.png" height="20" />
		<span class="font-medium ml-2">Chillpainai &#169; 2022</span>
		<span class="font-medium ml-2">Version {{ version }}</span>
	</div>
</template>

<script>
	import { version } from '../package.json';

	export default {
		name: "AppFooter",
		data: () => ({
			version
		}),
		methods: {
			footerImage() {
				return this.$appState.darkTheme ? 'images/logo-white.svg' : 'images/logo-dark.svg';
			}
		},
		computed: {
			darkTheme() {
				return this.$appState.darkTheme;
			}
		}
	}
</script>