import { defineStore } from 'pinia'

import axios from "@/plugins/axios"

import { name, version } from '../../package.json'

require('dotenv').config()
const VUE_APP_ENDPOINT_URL = process.env.VUE_APP_ENDPOINT_URL

// useStore could be anything like useUser, useCart
// the first argument is a unique id of the store across your application
export const useAuthStore = defineStore('auth', {
  persist: {
    key: `${name}-auth-${version.slice(0, version.length - 2)}.x`,
    paths: ['accessToken', 'user']
  },
  
  state: () => ({
    accessToken: null,
    logged: false,
    user: {}
  }),
  actions: {
    getAuth: () => axios.get(VUE_APP_ENDPOINT_URL + '/api/auth/get'),

    login: ({ email, password }) => axios.post(VUE_APP_ENDPOINT_URL + '/api/login', { email, password }),

    logout () {
      const accessToken = null
      this.setToken({ accessToken })

      this.logged = false
      this.user = {}
    },

    setAxios ({ accessToken }) {
      axios.defaults.headers.common = { Authorization: `Bearer ${accessToken}` }
    },

    setLogged () {
      this.logged = true
    },

    setToken ({ accessToken }) {
      this.accessToken = accessToken

      this.setAxios({ accessToken })
    },

    setUser (user) {
      this.user = user
    }
  }
})
